import Navbar from '../1.Navbar.js';
import ContainerContatti from '../ContattiComponenti/1.ContainerContatti.js';
import Contatti1 from '../5.1Contatti1.js';
const SchedaContatti = () => {

    return(

        <div>
    <Navbar/>
    <ContainerContatti/>
    <Contatti1/>
    </div>
    

    );
}

export default SchedaContatti;