import Navbar from '../1.Navbar.js';

import Progetti1 from '../3.1Progetti1.js';
const SchedaProgetti = () => {

    return(

        <div>
    <Navbar/>
    <div className='testoprogetti'>
        
<h1><em>“Sezione Progetti"</em></h1>
<p>Questo spazio sarà dedicato alla pubblicazione di tutti i miei progetti, indipendentemente che siano di natura personale o lavorativa.</p>
        </div>
    <Progetti1/>
   
    </div>
    

    );
}

export default SchedaProgetti;