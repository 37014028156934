import Navbar from '../1.Navbar.js';
import Contattis from '../5.Contattis.js';
import Bio from '../ChiComponenti/1.Bio.js';
import ChiFiltro from '../ChiComponenti/ChiFiltro.js';

const SchedaChi = () => {

    return(

        <div>
    <Navbar/>
    <Bio/>
    <ChiFiltro/>
    </div>
    

    );
}

export default SchedaChi;